<template>
  <div id="chart-dashboard">
    <canvas
      ref="chart"
      :style="{width : '100%'}"
      height="400"
      style="position:relative;"
    />
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import dayjs from 'dayjs';
import { externalTooltipHandler } from '@/utils/chart';

Chart.register(...registerables);

const DEFAULT_DATASET = {
  label: 'Уникальных посетителей',
  pointRadius: 6,
  pointHoverRadius: 6,
};

export default {
  name: 'ChartDashboard',
  props: {
    chartData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    chartRef() {
      return this.$refs.chart.getContext('2d');
    },
  },
  watch: {
    chartData: {
      deep: true,
      handler() {
        this.updateChart();
      },
    },
  },
  mounted() {
    this.createChart();
  },
  methods: {
    createChart() {
      const { data, labels } = this.formDataset(this.chartData);
      const gradient = this.chartRef.createLinearGradient(0, 0, 0, 450);
      gradient.addColorStop(0, 'rgba(0, 160, 242, 0.4)');
      gradient.addColorStop(1, 'rgba(84, 47, 243, 0.4)');
      this.chart = new Chart(this.chartRef, {
        type: 'line',
        data: {
          labels,
          datasets: [{
            ...DEFAULT_DATASET,
            data,
            cubicInterpolationMode: 'monotone',
            tension: 0.4,
            fill: true,
            pointBackgroundColor: '#8089e5',
            backgroundColor: gradient,
            borderColor: ['#8089e5'],
          }],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              mode: 'point',
              position: 'average',
              intersect: true,
              enabled: false,
              displayColors: false,
              xAlign: 'left',
              yAlign: 'center',

              external: externalTooltipHandler,
            },
          },

          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                precision: 0,
              },
            },
          },
        },
      });
    },
    updateChart() {
      const { data, labels } = this.formDataset(this.chartData);
      const gradient = this.chartRef.createLinearGradient(0, 0, 0, 450);
      gradient.addColorStop(0, 'rgba(0, 160, 242, 0.4)');
      gradient.addColorStop(1, 'rgba(84, 47, 243, 0.4)');

      /**
       * Надо мутировать конфиг для изменения графика
       * @see https://www.chartjs.org/docs/2.7.0/developers/updates.html#adding-or-removing-data
       */
      this.chart.data.labels = labels;
      this.chart.data.datasets = [{
        ...DEFAULT_DATASET,
        data,
        cubicInterpolationMode: 'monotone',
        tension: 0.4,
        fill: true,
        pointBackgroundColor: '#8089e5',
        backgroundColor: gradient,
        borderColor: ['#8089e5'],
      }];

      this.chart.update();
    },
    formDataset(raw) {
      const data = raw.map((i) => i.score);
      const labels = raw.map((i) => dayjs(i.date).format('DD.MM'));
      return { data, labels };
    },
    getOrCreateTooltip(chart) {
      let tooltipEl = chart.canvas.parentNode.querySelector('#chartjs-tooltip');
      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        chart.canvas.parentNode.appendChild(tooltipEl);
      }
      return tooltipEl;
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
