<template>
  <VContainer
    fluid
  >
    <VRow>
      <VCol>
        <div class="tt-text-headline-1">
          Дашборд
        </div>
      </VCol>
    </VRow>
    <VRow v-if="licensesStatistic">
      <VCol
        md="4"
        sm="6"
        xs="12"
      >
        <CardWrapper data-test="card-balance-license">
          <VContainer class="pa-0">
            <VRow
              dense
              align="center"
            >
              <VCol cols="12">
                <span class="tt-text-title-1 tt-light-mono-100--text">
                  {{ licensesStatistic.total_license_count }}
                </span>
              </VCol>
              <VCol cols="12">
                <span class="tt-text-body-2 tt-light-mono-64--text">
                  баланс лицензий
                </span>
              </VCol>
            </VRow>
          </VContainer>
        </CardWrapper>
      </VCol>
      <VCol
        md="8"
        sm="6"
        xs="12"
      >
        <CardWrapper data-test="card-license-statistic">
          <VContainer class="pa-0">
            <VRow
              justify="space-between"
              no-gutters
            >
              <VCol cols="auto">
                <span class="tt-text-body-2">
                  {{
                    licensesStatistic.used_license - licensesStatistic.test_license_count
                  }}</span>
              </VCol>
              <VCol cols="auto">
                <span class="tt-text-body-2">{{ licensesStatistic.licenses_left }}</span>
              </VCol>
            </VRow>
            <VRow no-gutters>
              <VCol>
                <VProgressLinear
                  :value="licensesStatistic.used_percent"
                  rounded
                  color="#5C6BC0"
                />
              </VCol>
            </VRow>
            <VRow align="center">
              <VCol cols="auto">
                <VRow
                  align="center"
                  dense
                >
                  <VCol cols="auto">
                    <VSheet
                      height="4"
                      width="24"
                      class="rounded-lg"
                      color="#5C6BC0"
                    />
                  </VCol>
                  <VCol cols="auto">
                    <div class="tt-text-body-2 tt-light-mono-46--text">
                      использовано
                    </div>
                  </VCol>
                </VRow>
              </VCol>
              <VCol cols="auto">
                <VRow
                  align="center"
                  dense
                >
                  <VCol cols="auto">
                    <VSheet
                      height="4"
                      width="24"
                      class="rounded-lg"
                      color="secondary"
                    />
                  </VCol>
                  <VCol cols="auto">
                    <div class="tt-text-body-2 tt-light-mono-46--text">
                      осталось
                    </div>
                  </VCol>
                </VRow>
              </VCol>
            </VRow>
          </VContainer>
        </CardWrapper>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="6">
        <TTSelect
          :value="locationId"
          :items="locations"
          item-value="id"
          item-text="name"
          large
          :menu-props="{ auto : true }"
          class="tt-select-attach"
          attach
          @change="handleLocation"
        >
          <template #selection="{item,disabled}">
            <TextTemplate
              class="v-select__selection v-select__selection--comma"
              :disabled="disabled"
              :text="item.name"
              :show-prefix="!item.is_active && !!item.id"
            />
          </template>
          <template #item="{item}">
            <TextTemplate
              :text="item.name"
              :show-prefix="!item.is_active && !!item.id"
            />
          </template>
        </TTSelect>
      </VCol>
    </VRow>
    <VRow>
      <VCol
        md="4"
        sm="6"
        xs="12"
      >
        <CardWrapper
          link
          :to="{name : Names.R_EVENTS}"
          data-test="button-goto-event"
        >
          <VCardTitle
            data-test="title-dashboard-active-events"
            class="pa-1 px-0"
          >
            <div class="tt-text-title-1 light-mono-100--text">
              {{ dashboard.active_events }}
            </div>
          </VCardTitle>
          <VCardText class="pa-1 px-0">
            <div class="tt-text-body-2 tt-light-mono-46--text">
              активных событий
            </div>
          </VCardText>
        </CardWrapper>
      </VCol>
      <VCol
        md="4"
        sm="6"
        xs="12"
      >
        <CardWrapper
          link
          :to="{name : Names.R_REPORT_MEETING}"
          data-test="button-goto-report-meeting"
        >
          <VCardTitle
            data-test="title-dashboard-actual-checkpoints"
            class="pa-1 px-0"
          >
            <div class="tt-text-title-1 light-mono-100--text">
              {{ dashboard.actual_checkpoints }}
            </div>
          </VCardTitle>
          <VCardText class="pa-1 px-0">
            <div class="tt-text-body-2 tt-light-mono-46--text">
              встреч требуют отчета
            </div>
          </VCardText>
        </CardWrapper>
      </VCol>
      <VCol
        md="4"
        sm="6"
        xs="12"
      >
        <CardWrapper
          link
          :to="{name : Names.R_APPEAL}"
          data-test="button-goto-appeal"
        >
          <VCardTitle
            data-test="title-dashboard-not-resolved-appeals"
            class="pa-1 px-0"
          >
            <div class="tt-text-title-1 light-mono-100--text">
              {{ dashboard.not_resolved_appeals }}
            </div>
          </VCardTitle>
          <VCardText class="pa-1 px-0">
            <div class="tt-text-body-2 tt-light-mono-46--text">
              новых обращений
            </div>
          </VCardText>
        </CardWrapper>
      </VCol>
    </VRow>
    <VRow>
      <VCol
        md="4"
        sm="6"
        xs="12"
      >
        <CardWrapper
          link
          data-test="button-goto-staff-invited"
          @click="gotoStaff(locationId, '#state=INVITED')"
        >
          <VCardTitle
            data-test="title-dashboard-staff-not-started"
            class="pa-1 px-0"
          >
            <div class="tt-text-title-1 light-mono-100--text">
              {{ dashboard.staff_not_started }}
            </div>
          </VCardTitle>
          <VCardText class="pa-1 px-0">
            <div class="tt-text-body-2 tt-light-mono-46--text">
              не начали адаптацию
            </div>
          </VCardText>
        </CardWrapper>
      </VCol>
      <VCol
        md="4"
        sm="6"
        xs="12"
      >
        <CardWrapper
          link
          data-test="button-goto-staff-adapting"
          @click="gotoStaff(locationId, '#state=ADAPTING')"
        >
          <VCardTitle
            data-test="title-dashboard-staff-in-progress"
            class="pa-1 px-0"
          >
            <div class="tt-text-title-1 light-mono-100--text">
              {{ dashboard.staff_in_progress }}
            </div>
          </VCardTitle>
          <VCardText class="pa-1 px-0">
            <div class="tt-text-body-2 tt-light-mono-46--text">
              проходят адаптацию
            </div>
          </VCardText>
        </CardWrapper>
      </VCol>
      <VCol
        md="4"
        sm="6"
        xs="12"
      >
        <CardWrapper
          link
          data-test="button-goto-staff-adapted"
          @click="gotoStaff(locationId, '#state=ADAPTED')"
        >
          <VCardTitle
            data-test="title-dashboard-staff-finished"
            class="pa-1 px-0"
          >
            <div class="tt-text-title-1 light-mono-100--text">
              {{ dashboard.staff_finished }}
            </div>
          </VCardTitle>
          <VCardText class="pa-1 px-0">
            <div class="tt-text-body-2 tt-light-mono-46--text">
              завершили адаптацию
            </div>
          </VCardText>
        </CardWrapper>
      </VCol>
    </VRow>
    <VDivider class="my-9" />
    <VRow>
      <VCol
        cols="12"
        class="pt-0"
      >
        <div class="tt-text-headline-1">
          Активность использования приложения
        </div>
      </VCol>
      <VCol cols="12">
        <ChartDashboard
          v-if="dots.length"
          class="dashboard-box pa-6"
          :chart-data="dots"
        />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import ChartDashboard from '@/components/chart/ChartDashboard.vue';
import { pageLocationToken } from '@/services';
import TextTemplate from '@/components/shared/TextTemplate.vue';
import CardWrapper from '@/components/shared/CardWrapper.vue';

export default {
  name: 'Home',
  components: {
    CardWrapper,
    TextTemplate,
    ChartDashboard,
  },
  inject: ['Names'],
  data() {
    return {
      chart: null,
      locationId: null,
      locations: [],
      licenses: {},
      dashboard: {
        staff_finished: 0,
        staff_in_progress: 0,
        staff_not_started: 0,
        locations: 0,
        active_events: 0,
        actual_checkpoints: 0,
        not_resolved_appeals: 0,
        dots: [],
      },
    };
  },
  computed: {
    dots() {
      return this.dashboard.dots;
    },
    licensesStatistic() {
      return {
        ...this.licenses,
        // eslint-disable-next-line max-len,vue/max-len
        licenses_left: this.licenses.total_license_count - this.licenses.used_license + this.licenses.test_license_count,
        used_percent: (this.licenses.used_license * 100) / this.licenses.total_license_count,
      };
    },
  },
  async created() {
    this.$repositories.location.list({})
      .then((r) => {
        const { data } = r.data;
        // из строки "все локации (n)" делаем объект, что бы затем его положить в массив с локациями,
        // что бы его (объект) можно было профильтровать
        const defaultLocations = {
          id: null,
          is_active: true,
          name: `Все локации (${data.length})`,
        };
        // выводим массив локаций с новым объектом, а так же профильрованный массив локаций
        this.locations = [defaultLocations, ...data];
      })
      .catch((e) => console.warn(e));

    // pageLocationToken - это просто хранилище данных, которые хранятся в браузере
    // когда мы вызываем метод getToken() то запрашиваем данные. в нашем случаи это id локации - число
    const loc = pageLocationToken.get();
    this.locationId = loc ? parseInt(loc, 10) : null;
    this.handleLocationChange(loc);
  },
  methods: {
    handleLocation(id) {
      pageLocationToken.set(id);
      this.locationId = id;
      this.handleLocationChange(id);
    },
    handleLocationChange(loc) {
      let payload = {};
      if (loc) {
        payload = { params: { locationId: loc } };
      }
      this.$repositories.dashboard.list(payload)
        .then((r) => {
          const { data } = r.data;
          this.dashboard = data;
          this.licenses = r.data.license;
        })
        .catch((e) => console.warn(e));
    },
    gotoStaff(locationId, hash) {
      this.$router.push({ name: this.Names.R_STAFF, query: { locationId }, hash });
    },
  },
};
</script>

<style>
.dashboard-box {
  border: none;
  box-shadow: 0px 2px 24px rgba(11, 18, 24, 0.08);
  border-radius: 8px;
}
</style>
